'use client';

import * as React from 'react';

export const SetNavigationTrackerContext = React.createContext<
  (navigationFrom: string) => void
>(() => {});
export const NavigationTrackerContext = React.createContext<
  string | null
>(null);

function NavigationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [mostRecentNavigationFrom, setMostRecentNavigationFrom] =
    React.useState<string | null>(null);

  return (
    <SetNavigationTrackerContext.Provider
      value={setMostRecentNavigationFrom}
    >
      <NavigationTrackerContext.Provider
        value={mostRecentNavigationFrom}
      >
        {children}
      </NavigationTrackerContext.Provider>
    </SetNavigationTrackerContext.Provider>
  );
}

export default NavigationProvider;
