/*
This hook is like `useBoop`, but lower-level. It has no API for
styles. Instead, it returns an `isBooped` value based on an
`isHovering` state.
*/

import * as React from 'react';

import usePrefersReducedMotion from '@/hooks/use-prefers-reduced-motion';

function useBoopMinimal(isHovering: boolean, timing = 150) {
  const [isBooped, setIsBooped] = React.useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  React.useEffect(() => {
    if (!isHovering || prefersReducedMotion) {
      return;
    }

    setIsBooped(true);
  }, [isHovering, prefersReducedMotion]);

  React.useEffect(() => {
    if (!isBooped) {
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);

  return isBooped;
}

export default useBoopMinimal;
