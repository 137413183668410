import * as React from 'react';

import { ColorMode } from '@/constants';

export type ToggleColorMode = (colorMode?: ColorMode) => void;
export type ToggleSoundEnabled = (value?: boolean) => void;

export const SAVED_SOUND_ENABLED_KEY = 'saved-sound-enabled';

export function useRestoredValuesFromDevice({
  initialColorMode,
  setColorMode,
  initialSoundEnabled,
  setSoundEnabled,
}: {
  initialColorMode: ColorMode;
  setColorMode: React.Dispatch<React.SetStateAction<ColorMode>>;
  initialSoundEnabled: boolean;
  setSoundEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  React.useEffect(() => {
    // Initializing color is weird, since we have that `THEME_INITIALIZER_SCRIPT` that writes a data-attribute to the root HTML tag. We’ll read that HTML value to get the actual color mode of the page.
    const appliedColorMode =
      document.documentElement.getAttribute('data-color-mode');

    if (
      checkIsColorMode(appliedColorMode) &&
      appliedColorMode !== initialColorMode
    ) {
      setColorMode(appliedColorMode);
    }

    // For sound, it's more traditional.
    const savedSoundEnabled = localStorage.getItem(
      SAVED_SOUND_ENABLED_KEY
    );

    if (typeof savedSoundEnabled === 'string') {
      const parsed = JSON.parse(savedSoundEnabled);

      if (
        typeof parsed === 'boolean' &&
        parsed !== initialSoundEnabled
      ) {
        setSoundEnabled(parsed);
      }
    }

    // React complains that we’re not depending on `initialColorMode` or `initialSoundEnabled`, but this is intentional; we *don't* want to re-run when these things change!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const checkIsColorMode = (
  colorMode: string | null
): colorMode is ColorMode => {
  return (
    typeof colorMode === 'string' &&
    ['dark', 'light'].includes(colorMode)
  );
};
