import { styled } from '@linaria/react';

import type { Props } from './VisuallyHiddenProps';

const VisuallyHidden = ({ children, ...delegated }: Props) => {
  return <Wrapper {...delegated}>{children}</Wrapper>;
};

const Wrapper = styled.span`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
`;

export default VisuallyHidden;
