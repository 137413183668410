// In development, I have a keyboard shortcut that allows me to view the hidden text. In production, it's a much simpler component.
// Decided to split it up this way to avoid polluting the client bundle with development-only code. This way, the production component is a Server Component.
import * as React from 'react';

import { Props } from './VisuallyHiddenProps';
// TypeScript doesn't understand CommonJS, but we need to use CommonJS to have conditional imports. Ignore this error.
// @ts-ignore
import VisuallyHiddenRaw from './VisuallyHiddenDynamic';

const VisuallyHidden = VisuallyHiddenRaw as React.FC<Props>;

export default VisuallyHidden;
