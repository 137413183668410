export {
  default,
  UserPreferencesContext,
  SetUserPreferencesContext,
  useColorMode,
} from './UserPreferencesProvider';

export type {
  ToggleColorMode,
  ToggleSoundEnabled,
} from './UserPreferencesProvider.helpers';
